<template>
  <section>
    <b-row class="match-height">
      <b-col md="4" class="altezza_min_card_utenti">        
        <b-card no-body header="Utenti">
            <div class="scrollable-container">
              <b-list-group flush>
                <b-list-group-item class="d-flex align-items-center" v-for="(user) in this.lista_utenti" :key="user.id">
                  <b-avatar
                    size="35"
                    variant="light-success"
                    badge
                    class="badge-minimal mr-1"
                    :badge-variant="user.availability_color"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="22"
                    />
                  </b-avatar>
                  <span>{{ user.user_name }} {{ user.user_surname }}</span>

                </b-list-group-item>

              </b-list-group>
            </div>

        </b-card>

      </b-col>

      <b-col md="8">
        <b-card
          no-body
          class="card-statistics"
          header="Aggiornamenti"
        >          
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                xl="4"
                sm="12"
                class=""
              >
                <b-media no-body>
                  <b-media-aside
                    vertical-align="center"
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-info"
                    >
                      <feather-icon
                        size="24"
                        icon="UsersIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto mb-0">
                    <h4 class="font-weight-bolder mb-0">
                      12
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Lead
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col
                xl="4"
                sm="12"
                class=""
              >
                <b-media no-body>
                  <b-media-aside
                    vertical-align="center"
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-warning"
                    >
                      <feather-icon
                        size="24"
                        icon="CalendarIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto mb-0">
                    <h4 class="font-weight-bolder mb-0">
                      6
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Attività
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col
                xl="4"
                sm="12"
                class=""
              >
                <b-media no-body>
                  <b-media-aside
                    vertical-align="center"
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-secondary"
                    >
                      <feather-icon
                        size="24"
                        icon="MailIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto mb-0">
                    <h4 class="font-weight-bolder mb-0">
                      21
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      Email
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
              

            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      
    </b-row>

    <b-row class="match-height">
      <b-col md="4">
        <b-card header="Server">
          <b-row align-v="center">
            <b-col
              xl="6"
              sm="12"
            >
              <b-media no-body>
                <b-media-aside
                  vertical-align="center"
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-secondary"
                  >
                    <feather-icon
                      size="24"
                      icon="ServerIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto mb-0">
                  <h4 class="font-weight-bolder mb-0">
                    Modalità
                  </h4>
                </b-media-body>
              </b-media>
            </b-col>

            <b-col
              xl="6"
              sm="12"
            >
              <span class="text-danger">Debug</span>
            </b-col>

          </b-row>

        </b-card>

      </b-col>

    </b-row>
    
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BCardText, BImg, BAvatar, BListGroup, BListGroupItem, BAlert, BMedia, BMediaAside, BMediaBody } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard, 
    BCardBody, 
    BCardText,
    BAvatar,
    BImg,
    BListGroup, 
    BListGroupItem, 
    BAlert, 
    BMedia, 
    BMediaAside, 
    BMediaBody, 

  },
  data() {
    return {
      userData: {},
      lista_utenti: {},

      Caricato: false,
      errore_caricamento: false,

      intervalId: null, // ID per controllare l'intervallo

    }
  },
  created() {
    // dati reali
    this.userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(this.userData)

    const listaUtenti = this.$http.get('api/crm/user/list_availabilities')

    Promise.all([listaUtenti]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista utenti
        this.lista_utenti = response[0].data.reply.dati
        
        this.Caricato = true;
        this.errore_caricamento = false;
        
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
        
    })
  },
  methods: {
    controlloStatoUtenti() {
      console.log("controlloStatoUtenti in Home - start 222")
      this.Caricato = false;
      this.errore_caricamento = false;

      this.$http.get('api/crm/user/list_availabilities').then(response => { 
        if(response.data.statusCode == 200){
          //caricamento lista utenti
          this.lista_utenti = response.data.reply.dati
          
          this.Caricato = true;
          this.errore_caricamento = false;
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      });

    },
    stopInterval() {
      clearInterval(this.intervalId); // Ferma l'intervallo
      this.intervalId = null;
      console.log("Intervallo controllo stato utenti fermato.");
    },
  },
  mounted() {
    // Avvia l'intervallo
    this.intervalId = setInterval(this.controlloStatoUtenti, 5000); // Ogni 5 secondi
    //console.log("Intervallo avviato.");
  },
  beforeRouteLeave(to, from, next) {
    //console.log("Prima di lasciare la pagina:", from.fullPath, "->", to.fullPath);

    //console.log("beforeRouteLeave --------------------")
    // Pulisce l'intervallo quando il componente viene distrutto
    clearInterval(this.intervalId);

    this.stopInterval();

    //console.log("beforeRouteLeave - prima cambio pagina --------------------")

    next(); //cambio pagina
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
